import { useContext } from 'react';
import { DialogContext } from '../components/Toast';

export const useToast = () => {
  const { openDialog, closeDialog } = useContext(DialogContext);

  return { openDialog, closeDialog };
};

export default useToast;
