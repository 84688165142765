import React from 'react';
import PropTypes from 'prop-types';
import CircleLoader from 'react-spinners/CircleLoader';
import Row from 'react-bootstrap/Row';
import { css } from '@emotion/core';
import Container from 'react-bootstrap/Container';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Spinner = (props) => {
  const { loading } = props;
  return (
    <Container fluid>
      <Row justify-content-md-center="true" style={{ padding: '200px' }}>
        <CircleLoader css={override} sizeUnit="px" size={150} color="#2e3192" loading={loading} />
      </Row>
    </Container>
  );
};

Spinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Spinner;
