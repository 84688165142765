import { Card } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

export const FormCard = ({ children, title }) => (
  <Card style={{ width: '30rem' }}>
    <Card.Header>{title}</Card.Header>
    <Card.Body>{children}</Card.Body>
  </Card>
);

FormCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
