import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import Spinner from '../Spinner';
import * as ROUTES from '../../constants/routes';

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const { firebase, users } = this.props;
    if (!users.length) {
      this.setState({ loading: true });
    }

    firebase.users().on('value', (snapshot) => {
      const { onSetUsers } = this.props;
      onSetUsers(snapshot.val());

      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    const { firebase } = this.props;
    firebase.users().off();
  }

  render() {
    const { users } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <h2>Users</h2>
        {loading && <Spinner loading={loading} />}
        <ul>
          {users.map((user) => (
            <li key={user.uid}>
              <span>
                <strong>ID:</strong> {user.uid}
              </span>
              <span>
                <strong>E-Mail:</strong> {user.email}
              </span>
              <span>
                <strong>Username:</strong> {user.username}
              </span>
              <span>
                <Link to={`${ROUTES.ADMIN}/${user.uid}`}>Details</Link>
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

UserList.propTypes = {
  firebase: PropTypes.shape({
    users: PropTypes.func.isRequired,
  }).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSetUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  users: Object.keys(state.userState.users || {}).map((key) => ({
    ...state.userState.users[key],
    uid: key,
  })),
});

const mapDispatchToProps = (dispatch) => ({
  onSetUsers: (users) => dispatch({ type: 'USERS_SET', users }),
});

export default compose(withFirebase, connect(mapStateToProps, mapDispatchToProps))(UserList);
