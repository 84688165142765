/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form, InputGroup, Table } from 'react-bootstrap';
import Placeholder from 'react-bootstrap/Placeholder';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BsArrowDownShort,
  BsArrowRepeat,
  BsArrowUpShort,
  BsFileEarmarkBarGraph,
  BsFileEarmarkText,
  BsFileEarmarkZip,
  BsSearch,
} from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import { FacilityRow } from './facilityRow';
import useDialog from '../../hook/useToast';
import { ToastType } from '../Toast';

export const Company = ({
  authUser,
  currentCompany,
  firebase,
  loadCompanies,
  setLoader,
  year,
  loading,
  showDelete,
}) => {
  const defaultKey = 'default';
  const [filter, setFilter] = useState('');
  const [ascOrder, setAscOrder] = useState(1);
  const [groupFacilities, setGroupFacilities] = useState(null);
  const facilityFilter = (f) => !filter || f.name.toLowerCase().includes(filter.toLowerCase());
  const { openDialog } = useDialog();

  useEffect(() => {
    const currentFacilities = new Map();
    if (currentCompany.facilities) {
      currentCompany.facilities.filter(facilityFilter).forEach((element) => {
        let key;
        if (element.groupName) {
          key = element.groupName;
        } else {
          key = defaultKey;
        }
        if (currentFacilities.has(key)) {
          currentFacilities.set(key, [...currentFacilities.get(key), element]);
        } else {
          currentFacilities.set(key, [element]);
        }
      });
    }
    setGroupFacilities(currentFacilities);
  }, [currentCompany, filter]);

  const generatePDF = async ({ companyName, facilityName, fileType }) => {
    setLoader(true);
    const data = {
      dataId: authUser.dataId.replace(/"/g, ''),
      companyName,
      facilityName,
      fileType,
      year: year.toString(),
    };
    const result = await firebase.generatePDF({
      ...data,
    });
    if (result.data.message === 'generado') {
      window.open(result.data.url, '_blank');
    } else {
      openDialog('Generate PDF fail!', '', ToastType.error);
    }
    setLoader(false);
  };

  const generateDOCX = async (c, f, fileType) => {
    setLoader(true);
    if (c.name && f.name) {
      const result = await firebase.generateDOCX({
        companyName: c.name,
        dataId: authUser.dataId,
        facilityName: f.name,
        fileType,
        year,
      });
      const { data } = result || {};
      const { fn } = data || {};
      window.open(
        `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${encodeURI(fn)}`,
        '_blank'
      );
      setLoader(false);
    }
  }; // generateDOCX

  const loadCompanyFromSheet = async (c) => {
    setLoader(true);
    if (c.name) {
      const result = await firebase.loadCompany({
        companyName: c.name,
        dataId: authUser.dataId,
        sheetId: c.sheetId,
        year,
      });
      const { data } = result || {};
      if (!data?.success && data?.error) {
        const message = data.error.replaceAll(', ', '\n');
        openDialog('Load company data fail!', message, ToastType.error);
      }
      loadCompanies(currentCompany.name).then(() => {
        setLoader(false);
      });
    }
  };

  const generateZIP = async (c) => {
    setLoader(true);
    if (c.name) {
      const result = await firebase.generateZIP({
        companyName: c.name,
        dataId: authUser.dataId,
        year,
      });
      const { data } = result || {};
      const { fn } = data || {};
      window.open(
        `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${encodeURI(fn)}`,
        '_blank'
      );
      setLoader(false);
    }
  }; // generateZIP

  return (
    <>
      <Card>
        <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
          {loading ? (
            <Placeholder style={{ width: '50%' }} animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          ) : (
            <h2>{currentCompany.name}</h2>
          )}
          <div
            style={{
              display: 'flex',
              minWidth: '30%',
              gap: '0.5rem',
              justifyContent: 'flex-end',
            }}
          >
            <Button variant="outline-danger" size="md" onClick={() => showDelete()}>
              <MdDelete /> Delete Company
            </Button>
            <Button
              variant="outline-primary"
              size="md"
              onClick={() => loadCompanyFromSheet(currentCompany)}
            >
              <BsArrowRepeat /> Reload
            </Button>
            <Button
              variant="primary"
              size="md"
              onClick={
                () =>
                  window.open(
                    `https://docs.google.com/spreadsheets/d/${currentCompany.sheetId}`,
                    '_blank'
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }
            >
              <BsFileEarmarkBarGraph /> Sheet
            </Button>
            <Button
              variant="outline-primary"
              size="md"
              disabled={!(currentCompany && currentCompany.facilities)}
              onClick={() => generateDOCX(currentCompany, currentCompany.facilities[0], 'mrl')}
            >
              <BsFileEarmarkText /> MRL
            </Button>
            <Button
              variant="outline-primary"
              size="md"
              disabled={!(currentCompany && currentCompany.facilities)}
              onClick={() => generateZIP(currentCompany)}
            >
              <BsFileEarmarkZip /> Zip
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Card.Title>
            <InputGroup>
              <InputGroup.Text>
                <BsSearch />
              </InputGroup.Text>
              <Form.Control
                size="lg"
                placeHolder="Search"
                onChange={(event) => setFilter(event.target.value)}
              />
            </InputGroup>
          </Card.Title>
          <Table>
            <thead>
              <tr>
                <th onClick={() => setAscOrder((old) => -1 * old)}>
                  Facility {ascOrder === 1 ? <BsArrowDownShort /> : <BsArrowUpShort />}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {groupFacilities &&
                Array.from(groupFacilities.keys())
                  .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1) * ascOrder)
                  .map((key) => {
                    return (
                      <>
                        {groupFacilities.size === 1 && key === defaultKey ? (
                          <> </>
                        ) : (
                          <tr>
                            <td colSpan={2} style={{ paddingLeft: 0 }}>
                              <h6 style={{ paddingTop: '8px' }}>Group: {key}</h6>
                            </td>
                          </tr>
                        )}
                        {groupFacilities
                          .get(key)
                          .sort((a, b) => (a.name > b.name ? 1 : -1) * ascOrder)
                          .map((f) => (
                            <FacilityRow
                              facility={f}
                              company={currentCompany}
                              generateDOCX={generateDOCX}
                              generatePDF={generatePDF}
                              loading={loading}
                            />
                          ))}
                      </>
                    );
                  })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

Company.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  firebase: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  authUser: PropTypes.object.isRequired,
  loadCompanies: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  currentCompany: PropTypes.shape({
    sheetId: PropTypes.string,
    name: PropTypes.string,
    facilities: PropTypes.arrayOf(
      PropTypes.shape({
        sheetId: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  showDelete: PropTypes.func.isRequired,
};
