import React from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import {
  BsBarChart,
  BsFileEarmarkBarGraph,
  BsFileEarmarkText,
  BsFileEarmarkPdf,
} from 'react-icons/bs';

export const FacilityRow = ({ facility, company, generateDOCX, generatePDF, loading }) => {
  const openSheet = () => {
    window.open(`https://docs.google.com/spreadsheets/d/${facility.sheetId}`, '_blank');
  };

  const generateLetter = () => {
    generateDOCX(company, facility, 'letter');
  };
  const generateReport = () => {
    generateDOCX(company, facility, 'report');
  };

  const generatePdfReport = () => {
    generatePDF({
      companyName: company.name,
      facilityName: facility.name,
      fileType: 'pdf',
    });
  };

  return (
    <tr key={facility.name}>
      <td>{facility.name}</td>
      <td
        style={{
          minWidth: '40vh',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '20px',
        }}
      >
        {facility.sheetId && (
          <Button variant="outline-primary" size="sm" onClick={openSheet}>
            <BsFileEarmarkBarGraph /> Sheet
          </Button>
        )}
        {facility.sheetId && (
          <Button variant="outline-primary" size="sm" onClick={generateLetter}>
            <BsFileEarmarkText /> Letter
          </Button>
        )}
        {facility.sheetId && (
          <Button variant="outline-primary" size="sm" onClick={generateReport}>
            <BsBarChart /> Report
          </Button>
        )}
        {facility.sheetId && (
          <Button
            variant="outline-primary"
            size="sm"
            onClick={generatePdfReport}
            disabled={loading}
          >
            <BsFileEarmarkPdf /> PDF
          </Button>
        )}
      </td>
    </tr>
  );
};

FacilityRow.propTypes = {
  facility: PropTypes.shape({
    sheetId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  company: PropTypes.object.isRequired,
  generateDOCX: PropTypes.func.isRequired,
  generatePDF: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
