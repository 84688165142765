import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { withFirebase } from '../Firebase';

const withEmailVerification = (Component) => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      // eslint-disable-next-line react/no-unused-state
      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      const { firebase } = this.props;
      // eslint-disable-next-line react/no-unused-state
      firebase.doSendEmailVerification().then(() => this.setState({ isSent: true }));
    };

    render() {
      return <Component {...this.props} />;
    }
  }

  WithEmailVerification.propTypes = {
    firebase: PropTypes.shape({
      doSendEmailVerification: PropTypes.func.isRequired,
    }).isRequired,
  };

  const mapStateToProps = (state) => ({
    authUser: state.sessionState.authUser,
  });

  return compose(withFirebase, connect(mapStateToProps))(WithEmailVerification);
};

export default withEmailVerification;
