import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      const { onSetAuthUser } = this.props;
      // eslint-disable-next-line no-undef
      onSetAuthUser(JSON.parse(localStorage.getItem('authUser')));
    }

    componentDidMount() {
      const { firebase, onSetAuthUser } = this.props;
      this.listener = firebase.onAuthUserListener(
        (authUser) => {
          // eslint-disable-next-line no-undef
          localStorage.setItem('authUser', JSON.stringify(authUser));
          onSetAuthUser(authUser);
        },
        () => {
          // eslint-disable-next-line no-undef
          localStorage.removeItem('authUser');
          onSetAuthUser(null);
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  WithAuthentication.propTypes = {
    onSetAuthUser: PropTypes.func.isRequired,
    firebase: PropTypes.shape({
      onAuthUserListener: PropTypes.func.isRequired,
    }).isRequired,
  };

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser) => dispatch({ type: 'AUTH_USER_SET', authUser }),
  });

  return compose(withFirebase, connect(null, mapDispatchToProps))(WithAuthentication);
};

export default withAuthentication;
