import React, { useState, createContext } from 'react';
import Toast from 'react-bootstrap/Toast';
import PropTypes from 'prop-types';

export const DialogContext = createContext();
export const ToastType = {
  success: '#198754',
  error: '#DC3545',
};
const ToastProvider = ({ children }) => {
  const [dialog, setDialog] = useState({ open: false });

  const closeDialog = () => {
    setDialog({ open: false });
  };

  const openDialog = (title, message, type) => {
    setDialog({
      open: true,
      title,
      message,
      type,
    });
  };

  return (
    <DialogContext.Provider
      value={{
        dialog,
        openDialog,
        closeDialog,
      }}
    >
      <div>
        {children}
        <Toast
          onClose={() => closeDialog()}
          show={dialog.open}
          delay={3000}
          position="top-end"
          style={{
            background: dialog.type,
            position: 'absolute',
            top: '16px',
            right: '16px',
            left: 'auto',
            bottom: 'auto',
            minWidth: '400px',
          }}
          autohide
        >
          <Toast.Header
            style={{
              background: 'none',
              display: 'flex',
              justifyContent: 'space-between',
              color: 'white',
            }}
          >
            <strong>{dialog.title}</strong>
          </Toast.Header>
          <Toast.Body
            style={{
              whiteSpace: 'break-spaces',
              color: 'white',
            }}
          >
            {dialog.message}
          </Toast.Body>
        </Toast>
      </div>
    </DialogContext.Provider>
  );
};

ToastProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ToastProvider;
