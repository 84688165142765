import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Alert, Button, Form } from 'react-bootstrap';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { FormCard } from '../FormCard';

const PasswordForgetPage = () => (
  <FormCard title="Forgot Password">
    <PasswordForgetForm />
  </FormCard>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;
    const { firebase } = this.props;
    firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="name@example.com"
            onChange={this.onChange}
          />
        </Form.Group>
        {error && <Alert variant="danger">{error.message}</Alert>}
        <Button style={{ width: '100%' }} size="lg" disabled={isInvalid} type="submit">
          Reset My Password
        </Button>
      </Form>
    );
  }
}

PasswordForgetFormBase.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  firebase: PropTypes.any.isRequired,
};

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
