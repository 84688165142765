import React from 'react';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';

const buttonStyle = { fontSize: 18, whiteSpace: 'nowrap', textAlign: 'center' };

const SignOutButton = ({ firebase }) => (
  <Nav.Link active={false}>
    <Button onClick={firebase.doSignOut} style={buttonStyle}>
      Sign Out
    </Button>
  </Nav.Link>
);

SignOutButton.propTypes = {
  firebase: PropTypes.shape({
    doSignOut: PropTypes.func.isRequired,
  }).isRequired,
};

export default withFirebase(SignOutButton);
