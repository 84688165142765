import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

const Navigation = ({ authUser }) => (
  <Nav className="justify-content-end" style={{ backgroundColor: '#2e3192' }}>
    {authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />}
  </Nav>
);

Navigation.propTypes = {
  authUser: PropTypes.shape({
    roles: PropTypes.shape({
      ADMIN: PropTypes.string,
    }),
  }),
};

Navigation.defaultProps = {
  authUser: null,
};

const buttonStyle = {
  fontSize: 18,
  whiteSpace: 'nowrap',
  textAlign: 'center',
};

const NavigationAuth = ({ authUser }) => (
  <>
    <LinkContainer to={ROUTES.MAIN} active={false}>
      <Nav.Link active={false}>
        <Button style={buttonStyle}>Main</Button>
      </Nav.Link>
    </LinkContainer>

    {!!authUser.roles[ROLES.ADMIN] && (
      <LinkContainer to={ROUTES.ADMIN}>
        <Nav.Link active={false}>
          <Button style={buttonStyle}>Admin</Button>
        </Nav.Link>
      </LinkContainer>
    )}
    <SignOutButton />
  </>
);

NavigationAuth.propTypes = {
  authUser: PropTypes.shape({
    roles: PropTypes.shape({
      ADMIN: PropTypes.string,
    }),
  }).isRequired,
};

const NavigationNonAuth = () => (
  <>
    <LinkContainer to={ROUTES.LANDING}>
      <Nav.Link active={false}>
        <Button style={buttonStyle}>Landing</Button>
      </Nav.Link>
    </LinkContainer>
    <LinkContainer to={ROUTES.SIGN_IN}>
      <Nav.Link active={false}>
        <Button style={buttonStyle}>Sign In</Button>
      </Nav.Link>
    </LinkContainer>
  </>
);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Navigation);
