import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

const nineMinutes = 1000 * 60 * 9;

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
    this.functions = app.functions();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();

    /* Variables */
    this.authUser = {};
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUserIn) => {
      this.authUser = authUserIn;
      if (this.authUser) {
        this.user(this.authUser.uid)
          .once('value')
          .then((snapshot) => {
            let dbUser = snapshot.val();

            // default empty roles
            if (!dbUser) {
              dbUser = {};
            }
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            this.authUser = {
              uid: this.authUser.uid,
              email: this.authUser.email,
              emailVerified: this.authUser.emailVerified,
              providerData: this.authUser.providerData,
              ...dbUser,
            };
            next(this.authUser);
          });
      } else {
        fallback();
      }
    });

  // *** Functions ***
  newCompany = async (params) => {
    const func = this.functions.httpsCallable('newCompanyHTTPS', { timeout: nineMinutes });
    return func(params).then((result) => {
      return result;
    });
  };

  loadCompany = async (params) => {
    const func = this.functions.httpsCallable('loadCompanyHTTPS', { timeout: nineMinutes });
    return func(params)
      .then((result) => {
        return result;
      })
      .catch((e) => {
        return e;
      });
  };

  generatePDF = async (params) => {
    const func = this.functions.httpsCallable('generateMarkdownPDFHTTPS', { timeout: nineMinutes });
    return func(params).then((result) => {
      return result;
    });
  };

  generateDOCX = async (params) => {
    const func = this.functions.httpsCallable('generateMarkdownDOCXHTTPS', {
      timeout: nineMinutes,
    });
    return func(params).then((result) => {
      return result;
    });
  };

  generateZIP = async (params) => {
    const func = this.functions.httpsCallable('generateZIPHTTPS', { timeout: nineMinutes });
    return func(params).then((result) => {
      return result;
    });
  };

  deleteCompany = async (params) => {
    const func = this.functions.httpsCallable('deleteCompanyHTTPS', { timeout: nineMinutes });
    return func(params).then((result) => {
      return result;
    });
  };

  // *** Storage ***
  storageRef = (rid) => this.storage.ref(rid);

  // *** Notes API ***

  notes = (nid) => this.db.ref(`data/mmp/notes/${nid}`);

  saveNote = (nid, updateObj) => this.db.ref(`data/mmp/notes`).child(`${nid}`).update(updateObj);

  // *** ChangeLog API ***

  changelog = (fid) => this.db.ref(`data/mmp/changelog/${fid}`);

  changelogs = () => this.db.ref('data/mmp/changelog');

  // *** Report API ***

  report = (fid) => this.db.ref(`data/mmp/reports/${fid}`);

  reports = () => this.db.ref('data/mmp/reports');

  // *** Agg API ***

  data = (node) => this.db.ref(`data/${this.authUser.dataId || 'srp'}/${node}`);

  findCompaniesByYear = (year) => this.data(`companies/${year}`);

  // *** Sorts API ***

  sort = (fid) => this.db.ref(`data/mmp/sorts/${fid}`);

  sorts = () => this.db.ref('data/mmp/sorts');

  // *** Files API ***

  file = (fid) => this.db.ref(`data/mmp/files/${fid}`);

  files = () => this.db.ref('data/mmp/files');

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Message API ***

  message = (uid) => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');

  // ** clean values **
  cleanName = (name) => name.replace(/[.#$/[\]]/g, '');
}

export default Firebase;
