/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import './style.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import { Dropdown, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { BsBuilding, BsCalendarDate, BsPlusCircle } from 'react-icons/bs';
import Spinner from '../Spinner';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import { Company } from './company';
import { DialogContext, ToastType } from '../Toast/index';

let companies = [];

class MainPageBase extends Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = DialogContext;
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      year: new Date().getFullYear(),
      currentCompany: {},
      show: false,
      newCompany: '',
      availableYears: this.initYearArray(),
      showDelete: false,
    };

    this.loadCompanies = this.loadCompanies.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.showDialogDelete = this.showDialogDelete.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.loadCompanies().then(() => {
      this.setState({ loading: false });
    });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { year } = this.state;
    if (prevState.year !== year) {
      this.loadCompanies().then(() => {
        this.setState({ loading: false });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  handleClose = () => this.setState({ show: false, newCompany: '' });

  handleCloseDelete = () => this.setState({ showDelete: false });

  handleSubmit = async () => {
    const { firebase, authUser } = this.props;
    const { year, newCompany } = this.state;
    const { openDialog } = this.context;

    const listCompany = await firebase.findCompaniesByYear(year).once('value');
    const listCompanyObj = listCompany.val();
    const names = [];

    if (listCompanyObj) {
      names.push(...Object.keys(listCompanyObj));
    }

    if (names.includes(newCompany)) {
      this.setState({
        loading: false,
        show: false,
        newCompany: '',
      });
      openDialog(`This company ${newCompany} exist!`, '', ToastType.error);
      return;
    }
    if (newCompany) {
      this.setState({ loading: true });
      await firebase.newCompany({ companyName: newCompany, dataId: authUser.dataId, year });
      this.loadCompanies().then(() => {
        this.setState({
          loading: false,
          show: false,
          newCompany: '',
          currentCompany: companies.find((c) => c.name === newCompany),
        });
      });
    }
  };

  handleDelete = async () => {
    this.handleCloseDelete();
    const { currentCompany } = this.state;
    const { firebase, authUser } = this.props;
    const { openDialog } = this.context;
    const { year } = this.state;

    this.setState({ loading: true });
    const response = await firebase.deleteCompany({
      companyName: currentCompany.name,
      dataId: authUser.dataId,
      year,
    });
    if (!response.data.success) {
      openDialog(`${response.data.error}`, '', ToastType.error);
    }
    if (response.data.success) {
      this.loadCompanies().then(() => {
        companies = companies.filter((item) => item.name !== currentCompany.name);
        if (companies.length === 0) {
          this.setState({
            currentCompany: {},
          });
        } else {
          this.setState({
            currentCompany: companies[0],
          });
        }
      });
    }

    this.setState({ loading: false });
  };

  initYearArray() {
    const currentYear = new Date().getFullYear();
    const yearArray = [];
    let i = 2021;
    while (i <= currentYear) {
      yearArray.push(i);
      i += 1;
    }
    return yearArray;
  }

  async loadCompanies(companyName) {
    const { firebase } = this.props;
    const { year } = this.state;

    const [cSnapshot] = await Promise.all([firebase.findCompaniesByYear(year).once('value')]);
    const companyObj = cSnapshot.val();
    if (companyObj) {
      const companyNames = Object.keys(companyObj);
      companies = companyNames
        .map((n) => {
          return { name: n, ...companyObj[n] };
        })
        .sort();
      if (companyName) {
        const companyTemp = companies.filter((company) => {
          return company.name === companyName;
        });
        this.setState({ currentCompany: companyTemp[0] });
      } else {
        this.setState({ currentCompany: companies[0] });
      }
    } else {
      this.setState({ currentCompany: {} });
    }
  }

  showDialogDelete() {
    this.setState({ showDelete: true });
  }

  updateWindowDimensions() {
    this.setState({});
  }

  render() {
    const { loading, year, currentCompany, show, newCompany, availableYears, showDelete } =
      this.state;
    const { authUser, firebase } = this.props;
    if (loading)
      return (
        <Container>
          <Spinner />
        </Container>
      );

    return (
      <Container>
        <Modal show={show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>New Company</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label htmlFor="inputPassword5">Please enter the company name</Form.Label>
            <Form.Control
              value={newCompany}
              onChange={(event) => this.setState({ newCompany: event.target.value })}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDelete} onHide={this.handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Company</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label htmlFor="inputPassword5">
              Do you want delete {currentCompany.name} company?
            </Form.Label>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseDelete}>
              Close
            </Button>
            <Button variant="danger" onClick={this.handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <div
          style={{
            display: 'flex',
            gap: 20,
            padding: '20px 0',
            justifyContent: 'end',
          }}
        >
          <Button
            variant="primary"
            size="md"
            onClick={() => {
              this.setState({ show: true });
            }}
            disabled={year === 2021}
          >
            <BsPlusCircle /> Create New Company
          </Button>

          <Dropdown>
            <Dropdown.Toggle variant="outline-primary">
              <BsCalendarDate /> {year}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {availableYears.map((y) => (
                <Dropdown.Item
                  key={y}
                  onClick={() => {
                    this.setState({ year: y });
                  }}
                >
                  {y}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle variant="outline-primary">
              <BsBuilding />

              {currentCompany?.name || ''}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {companies.map((c) => (
                <Dropdown.Item value={c.name} onClick={() => this.setState({ currentCompany: c })}>
                  {c.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Company
          loadCompanies={this.loadCompanies}
          year={year}
          authUser={authUser}
          currentCompany={currentCompany}
          firebase={firebase}
          setLoader={(value) => this.setState({ loading: value })}
          showDelete={this.showDialogDelete}
        />
      </Container>
    );
  }
}

MainPageBase.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  firebase: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  authUser: PropTypes.object.isRequired,
};

const condition = (authUser) => !!authUser;

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const MainPage = withFirebase(MainPageBase);

export default compose(
  connect(mapStateToProps),
  withEmailVerification,
  withAuthorization(condition),
  withRouter,
  withFirebase
)(MainPage);
